import React from 'react';

import Layout from '@common/Layout';
import Navbar from '@common/Navbar';

import Header from '@sections/Header';
import About from '@sections/About';
import Location from '@sections/Location';
import Book from '@sections/Book';
import Photos from '@sections/Photos';
import Faq from '@sections/Faq';
import Footer from '@sections/Footer';

const IndexPage = () => (
  <Layout>
    <Navbar />
    <Header />
    <About />
    <Location />
    <Photos />
    <Book />
    <Faq />
    <Footer />
  </Layout>
);

export default IndexPage;
